<template>
  <div class="create-page-main">
    <div class="create-form">
      <div v-if="dataTimingPopup.id === undefined" class="form-title p-4">
        タイミングポップアップ作成
      </div>
      <div
        class="form-title mb-4 p-4"
        v-else-if="dataTimingPopup.id !== undefined"
      >
        編集
      </div>
      <b-form :disabled="isLoading">
        <b-form-group id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text">NO</label>
          <div class="px-3 w-100">
            <b-form-input
              v-model="NO"
              disabled
              required
              style="color: black"
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group class="mt-4" id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text"
            >ポップアップ名（管理用）※ユーザーには公開されません<span
              class="textMust"
              >（必須）</span
            ></label
          >
          <div class="px-3 w-100">
            <b-form-input v-model="popup_title_admin" required></b-form-input>
          </div>
        </b-form-group>
        <label class="col-sm-12 font-weight-bold custom-text mt-4"
          >ポップアップ送信タイミング</label
        >
        <div class="px-3 w-100">
          <b-form-group class="col-sm-12 pl-0" v-slot="{ ariaDescribedby }">
            <div class="d-flex">
              <b-form-radio
                v-model="popup_transmission"
                class="mr-3"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="0"
                >即時</b-form-radio
              >
              <b-form-radio
                v-model="popup_transmission"
                class="mx-3"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
                >ログイン後即時</b-form-radio
              >
            </div>
          </b-form-group>
        </div>
        <b-form-group class="mt-4" id="input-group-5" label-for="input-5">
          <label class="col-sm-12 font-weight-bold custom-text"
            >ポップアップタイトル<span class="textMust">（必須）</span></label
          >
          <div class="px-3 w-100">
            <b-form-input v-model="popup_title" required></b-form-input>
          </div>
        </b-form-group>
        <b-form-group class="mt-4" id="input-group-8" label-for="input-8">
          <label class="col-sm-12 font-weight-bold custom-text"
            >ポップアップ内容</label
          >
          <div class="px-3 w-100">
            <input
              type="file"
              hidden
              ref="selectFile"
              id="idSelectFile"
              accept=".pdf"
              @change="changePDF($event)"
            />
            <vue-editor
              useCustomImageHandler
              @image-added="handleImageAdded"
              @image-removed="handleImageRemove"
              v-model="popup_content"
              class="mb-4"
            ></vue-editor>
          </div>
        </b-form-group>

        <div class="d-flex justify-content-center mt-4 py-4">
          <b-button
            v-on:click="CreateNewTimingPopUp()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
          >
            <span v-show="!isLoading"> 編集内容を反映する </span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </b-button>
          <b-button
            variant="secondary"
            class="mr-4 back-btn"
            v-on:click="returnList()"
            >戻る</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants.js";
export default {
  name: "CreatePage",
  components: {
    VueEditor,
    // draggable,
  },
  data() {
    return {
      popup_title_admin: "",
      popup_transmission: 0,
      popup_title: "",
      popup_content: "",
      isLoading: false,
      dataTimingPopup: {
        id: "",
        shop_id: "",
        no: "",
      },
      NO: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const { params } = this.$route;
    this.dataTimingPopup.id = params.id;
    this.dataTimingPopup.shop_id = this.shop_id;
    this.NO = this.$route.query.no;
    if (this.dataTimingPopup.id !== undefined) {
      this.getTimingPopUpById(this.dataTimingPopup);
    }
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  mounted() {
    let container = document.getElementsByClassName("ql-toolbar")[0];
    let item = document.createElement("span");
    item.classList.add("ql-formats");
    let input = document.createElement("button");
    input.addEventListener("click", this.toggleModal);
    input.setAttribute("type", "button");
    input.classList.add("ql-test");
    input.innerHTML = "PDF";
    item.appendChild(input);
    container.appendChild(item);
  },
  computed: {
    ...mapGetters(["timingPopupById", "success", "message", "error"]),
  },
  watch: {
    timingPopupById() {
      this.popup_title_admin = this.timingPopupById.popup_title_admin;
      this.popup_transmission = this.timingPopupById.popup_send;
      this.popup_title = this.timingPopupById.popup_title;
      this.popup_content = this.timingPopupById.popup_content;
    },
    success() {
      if (this.success) {
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions({ createTimingPopUp: "createTimingPopUp" }),
    ...mapActions({ getTimingPopUpById: "getTimingPopUpById" }),
    ...mapActions({ updateTimingPopUpByID: "updateTimingPopUpByID" }),
    async CreateNewTimingPopUp() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      const formData = {
        shop_id: shop_id,
        popup_title_admin: this.popup_title_admin,
        popup_send: this.popup_transmission,
        popup_title: this.popup_title,
        popup_content: this.popup_content,
        id: this.$route.params.id,
      };
      if (this.dataTimingPopup.id === undefined) {
        const request = await this.createTimingPopUp(formData);
        if (request.success === true) {
          this.$router.go(-1);
          this.$toasted.success(request.message);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      } else {
        const request = await this.updateTimingPopUpByID(formData);
        if (request.success === true) {
          this.$router.go(-1);
          this.$toasted.success(request.message);
        } else {
          this.isLoading = false;
          this.$toasted.error(request.message);
        }
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    submitEditChapter() {
      this.chapter[this.titleChapter].title = this.dataChapterEdit.title;
      this.chapter[this.titleChapter].chapter_content =
        this.dataChapterEdit.chapter_content;
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view");
      });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 10) {
        this.$toasted.error("画像1枚につき10MBまで");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.$route.params.id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error("PDFファイルは10MBを超えこえてはなりません");
      } else {
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.popup_content +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
</style>
